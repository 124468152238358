.pattern-container {
    position: fixed; /* Fijo para que el patrón se mantenga al hacer scroll */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Cubre toda la pantalla */
    z-index: -1; /* Detrás de otros elementos */
    overflow: hidden; /* Evita el desbordamiento de los íconos */
    pointer-events: none; /* Evita la interacción del mouse con los íconos */
    background-color: transparent;
  }
  