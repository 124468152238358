
.home-gen{
    background-color: transparent;
    z-index: 1;
    
}

.aver{
    background-color: black;
}


.home-back{
    display: flex;
    justify-content: center;
    position: relative; /* Esto permite la posición absoluta de los elementos dentro */
    width: 100%; 
    height: 100vh; 
    background-color: transparent;
}

.home-img {
    width: 100%; /* Hacer que la imagen ocupe todo el ancho del contenedor */
    height: 100%; /* Hacer que la imagen ocupe toda la altura del contenedor */
    object-fit: cover; /* Mantiene la proporción de la imagen */
    opacity: 0.30;
    position: absolute; /* La imagen se coloca en el fondo */
    top: 0;
    left: 0;
    z-index: 1; /* La imagen estará detrás del texto */
}

.home-backtext {
    padding: 10dvh;
    background-color: rgba(22, 22, 22, 0.397);
    background-image: radial-gradient(circle, black 1px, transparent 1px);
    background-size: 10px 10px;
    position: absolute;
    z-index: 2;
    color: white;
    text-align: center;
    border-radius: 10px;
    top: 50%; /* Centra verticalmente el texto */
    left: 50%; /* Centra horizontalmente el texto */
    transform: translate(-50%, -50%); /* Ajusta el centro para que el texto quede centrado */
    width: 80%; /* Ajusta el tamaño del contenedor de texto */
    max-width: 120dvh; /* Limita el tamaño máximo del contenedor */
}

.home-backtext h1 {
    background-color: transparent;
    font-size: 7dvh;
    font-style: italic;
    z-index: 4;
}


.home-title{
    text-align: center;
    color: white;
    font-size: 6dvh;
    margin-top: 10dvh;
    background-color: rgba(255, 0, 0, 0);
}

.home-3piece{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin-top: 5dvh;
    justify-items: center;
    margin-bottom: 10dvh;
    margin-left: 5dvh;
    margin-right: 5dvh;
    background-color: transparent;
}

.tpiece-img{
    width: 50dvh;
    height: 28dvh;
    object-fit: cover;
    transition: transform 1s ease;
    border-radius: 5px; /* Opcional para esquinas redondeadas */
    background-color: transparent;
}

.tpiece-img:hover{
    transform: scale(1.15); /* Agrandar imagen */
    box-shadow: 0 4px 15px #1d1f34; /* Agregar brillo/sombra */
}

.home-gif{
    background-color: transparent;
    width: 20dvh;
}

