.App{

}

.all-back{
    height: 100dvh;
    color: red;
    width: 100%;
    background-color: black;
    position: fixed;
    z-index: -10;
}