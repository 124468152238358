.navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    /* background-color: rgb(1, 1, 26); */
    background-color: #1d1f34;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 6px 15px rgba(60, 60, 90, 0.9);

}

.nav-icon {
    background-color: #1d1e33;
    height: 9dvh;
    margin-left: 5dvh;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 50%; /* Mejora estética: siempre redondeado */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0); /* Sin glow inicial */
    margin-top: .5dvh;
}

.nav-icon:hover {
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.6); /* Glow celeste */
}

.nav-icon:active {
    border: 2px solid rgba(0, 255, 255, 1); /* Borde visible y celeste */
    animation: borderPulse 0.3s ease; /* Animación breve */
}

/* Animación para el borde */
@keyframes borderPulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 15px rgba(0, 255, 255, 0.6);
    }
    50% {
        transform: scale(1.05); /* Leve agrandamiento */
        box-shadow: 0 0 25px rgba(0, 255, 255, 1); /* Glow más fuerte */
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 15px rgba(0, 255, 255, 0.6); /* Vuelve al glow inicial */
    }
}


.nav-links{
    background-color: #1d1e33;
    display: flex;
    gap: 10dvh;
    margin-right: 5dvh;
    margin-bottom: 1dvh;
}

.nav-links a{
    background-color: #1d1e33;
    color: white;
    text-decoration: none;
    font-family: "Fascinate", system-ui;
    font-weight: 400;
    font-style: normal;
    font-size: 7dvh;
    text-align: center;
    object-fit:contain;
    margin-bottom: 0dvh;
    padding-bottom: 0dvh;
    text-shadow: 
    2px 0 black, /* Derecha */
    -2px 0 black, /* Izquierda */
    0 2px black, /* Abajo */
    0 -2px black, /* Arriba */
    2px 2px black, /* Diagonal derecha abajo */
    2px -2px black, /* Diagonal derecha arriba */
    -2px 2px black, /* Diagonal izquierda abajo */
    -2px -2px black; /* Diagonal izquierda arriba */
}

.nav-links a:hover{
    color: aqua;
}
